<template>
  <div>
    <v-row v-if="loading">
      <v-col
        :cols="cols"
        v-for="n in no"
        :key="`sketlon-${n}`"
      >
        <v-skeleton-loader
          ref="skeleton"
          :loading="loading"
          :boilerplate="false"
          :type="type"
          :tile="false"
          class="mx-auto"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    no: {
      type: Number,
      default: 6
    },
    cols: {
      type: Number,
      default: 12
    },
    type: {
      type: String,
      default: "table-heading, list-item-two-line, image, table-tfoot" //https://vuetifyjs.com/en/components/skeleton-loaders/
    }
  }
});
</script>

<style>
</style>