<template>
  <div class="p-10">
    <v-avatar
      v-if="image"
      tile
      class="d-inline-flex mr-3 mt-3"
      size="64"
    >
      <v-img
        :src="$vuetify.theme.dark || currentTheme.navDark ? 'https://cdn.appedr.com/img/logo/grand-center-w.png' : 'https://cdn.appedr.com/img/logo/grand-center.png'"
        contain
        alt="logo"
      />
    </v-avatar>
    <!-- <v-toolbar-title v-if="text" class="d-inline-flex text-uppercase font-weight-black ">{{ $t("app.name") }}</v-toolbar-title> -->
  </div>
</template>

<script>
import useSiteSetting from "@/composition/UseSiteSetting";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "WeeLogo",
  props: {
    text: {
      type: Boolean,
      default: true
    },
    image: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { root }) {
    const { currentTheme } = useSiteSetting(root);

    return {
      currentTheme
    };
  }
});
</script>