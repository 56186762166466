<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <v-btn
      dark
      text
      @click="snackbar = false"
    >
      {{$t('base.close')}}
    </v-btn>
  </v-snackbar>
</template>

<script>
import {
  defineComponent,
  ref,
  watch
} from "@vue/composition-api";
export default defineComponent({
  name: "WeeSnackbar",
  props: {
    value: Boolean,
    mode: {
      type: String,
      default: "multi-line" //vertical, multi-line
    },
    color: {
      type: String,
      default: "info"
    },
    text: {
      type: String,
      default: "Hello, I'm a snackbar"
    },
    x: {
      type: String,
      default: null// left, right, top, bottom
    },
    y: {
      type: String,
      default: "top" //top, bottom
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  setup(props, { emit }) {
    const snackbar = ref(false);
    watch(
      () => props.value,
      (val, oldVal) => {
        if (val === oldVal) return;
        snackbar.value = val;
      }
    );
    watch(snackbar, (val, oldVal) => {
      if (val === oldVal) return;
      emit("input", val);
    });
    return {
      snackbar
    };
  }
});
</script>
