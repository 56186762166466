import axios from "axios";

export const AppPrefix = "vee_theme";
export const AppVersion = "1.0.5";
export const CodeVersion = 5;
export const PoroductionMode = true;
export const DefaultLocale = process.env.VUE_APP_I18N_LOCALE;
export const DefaultPassword = process.env.VUE_APP_DEFAULT_PASSWORD;
export const DefaultSocketKey = process.env.VUE_APP_DEFAULT_SOCKET_KEY;
export const LocaleOptions = [
  { id: "en", name: "English" },
  { id: "th", name: "ไทย" }
];
export const DefaultTheme = {
  color: "#02C39A",
  darkMode: false,
  barColor: "to bottom, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)",
  barImage:
    "https://images.pexels.com/photos/2987769/pexels-photo-2987769.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  barImageShow: false,
  barDark: false,
  barIconColor: false,
  barExpandOnHover: false,
  navDark: false,
  navClippedLeft: true
};
export const ThemeColors = [
  "#00D1B2",
  "#008bf2",
  "#7367F0",
  "#02C39A",
  "#ff5e3a",
  "#28C76F",
  "#EA5455",
  "#FF2768",
  "#FFD60A",
  "#FF9F43",
  "#3B295D",
  "#5BC0EB",
  "#A5BE00",
  "#b8c2cc"
];

export const LocalStorage = {
  USER: `${AppPrefix}_user`,
  LANGUGE: `${AppPrefix}_languge`,
  THEME: `${AppPrefix}_theme`,
  CHECK_IN_USER: `${AppPrefix}_check_in_user`
};
export const DefaultApiEndpoint = PoroductionMode
  ? "https://centerapi.appedr.com"
  : "http://192.168.7.228:8090";
// export const DefaultApiEndpoint = PoroductionMode? "https://centerapi.appedr.com": "http://localhost/grand-center-api";
export const ApiClient = "default";
export const DefaultAxiosHeader = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8",
  "X-Locale": localStorage.getItem(LocalStorage.LANGUGE)
    ? localStorage.getItem(LocalStorage.LANGUGE)
    : DefaultLocale,
  "Code-Version": CodeVersion,
  apiClient: ApiClient,
  Authorization: ""
};
export const DefaultAxios = axios.create({
  baseURL: DefaultApiEndpoint,
  withCredentials: false,
  headers: DefaultAxiosHeader,
  timeout: 60 * 1000
});
export const MultipartAxios = axios.create({
  baseURL: DefaultApiEndpoint,
  headers: DefaultAxiosHeader,
  timeout: 60 * 1000
});

export const TimeIntervalConfig = PoroductionMode ? 5 * 60 * 1000 : 30 * 1000; // production 5*60*1000   5 minute
export const AxiosMethod = { GET: 1, POST: 2, PUT: 3, DELETE: 4 };

// custom app
// For excute cmd to Edr Server
export const DefaultAxiosHeaderForExcuteCmdToEdr = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8",
  "X-Locale": localStorage.getItem(LocalStorage.LANGUGE)
    ? localStorage.getItem(LocalStorage.LANGUGE)
    : DefaultLocale,
  "X-Api-Client": "grand-center",
  Authorization: ""
};
export const DefaultAxiosForExuteCmdToEdr = axios.create({
  baseURL: "",
  withCredentials: false,
  headers: DefaultAxiosHeaderForExcuteCmdToEdr,
  timeout: 60 * 60 * 1000 // 60 minute
});

export const SocketTopic = {
  MONITOR_CHECK_IN: `socket.monitor.check.in.`
};
