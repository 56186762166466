import Service from './Service';

class AuthService extends Service{
    constructor(){
        super();
    }
    async signin(postData){
        return this.callApiPost(`/signin`,postData);
    }
    async userLogout(){
        return this.callApiPost(`/userLogout`,{});
    }
    async userCheckAuth(){
        return this.callApiPost(`/userCheckAuth`,{});
    }
    async changePassword(postData){
        return this.callApiPost(`/userChangePwd`,postData);
    }

}
export default AuthService