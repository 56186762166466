// import { getCurrentUser } from "../plugins/util";
// import {LocalStorage} from "../plugins/config";

import AuthService from "../api/AuthService";
const authService = new AuthService();

export default async (to, from, next) => {
  // const userLoged = await getCurrentUser();
  // if (userLoged) {
  //   next();
  // } else {
  //   next("/auth/signin");
  // }

  //validate to your api server
  const { response } = await authService.userCheckAuth();
  if (response && response.status) {
    next()
  } else {
    next('/auth/signin')
  }
  // if (error) {
  //   console.error(error);
  // }
};
