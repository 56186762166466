import Vue from 'vue'
import VueMoment from 'vue-moment'
const moment = require('moment')
require('moment/locale/en-gb')
require('moment/locale/th')

import { getCurrentLocale } from './util'

const locale = getCurrentLocale()
moment.locale(locale);
Vue.use(VueMoment, {
    moment
})