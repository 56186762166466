import Vue from "vue";
import Router from "vue-router";
import AuthRequired from "./authRequired";

Vue.use(Router);
// import i18n from '@/plugins/i18n'
export default new Router({
  mode: "history", // hash, history
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Layout/App"),
      beforeEnter: AuthRequired,
      children: [
        // Dashboard
        {
          name: "App",
          path: "",
          component: () => import("@/views/App/Dashboard"),
          meta: {
            breadcrumb: [
              // { text: 'nav.dashboard', href: '/', disabled: false, i18n: true },
              { text: "nav.dashboard", href: "", disabled: true }
            ],
            pageTitle: { text: "nav.dashboard" }
          }
        },
        // Central data
        {
          path: "central-data",
          component: () => import("@/views/App/pages/central-information"),
          // redirect: "/central-data/home",
          children: [
            {
              name: "manage-youtube",
              path: "manage-youtube",
              component: () =>
                import(
                  "@/views/App/pages/central-information/YoutubeManagement"
                ),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "nav.youtubeLink", href: "", disabled: true }
                ],
                pageTitle: { text: "nav.youtubeLink", icon: "mdi-youtube" }
              }
            },
            {
              name: "line-notify",
              path: "line-notify",
              component: () =>
                import(
                  "@/views/App/pages/central-information/LineNotify.vue"
                ),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "model.line_notify.line_notify", href: "", disabled: true }
                ],
                pageTitle: { text: "model.line_notify.line_notify", icon: "mdi-bell-outline" }
              }
            },
            {
              name: "education-calendar",
              path: "education-calendar",
              component: () =>
                import(
                  "@/views/App/pages/central-information/EducationCalendar.vue"
                ),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "model.education_calendar.education_calendar", href: "", disabled: true }
                ],
                pageTitle: { text: "model.education_calendar.education_calendar", icon: "mdi-calendar-edit" }
              }
            }
          ]
        },
        // admin
        {
          path: "admin",
          component: () => import("@/views/App/pages/admin"),
          redirect: "/admin/user",
          children: [
            {
              path: "role",
              name: "admin-role",
              component: () => import("@/views/App/pages/admin/Role"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "nav.appUserRole", href: "", disabled: true }
                ],
                pageTitle: {
                  text: "nav.appUserRole",
                  icon: "mdi-account-group"
                }
              }
            },
            {
              path: "user",
              name: "admin-user",
              component: () => import("@/views/App/pages/admin/User"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "nav.appUser", href: "", disabled: true }
                ],
                pageTitle: { text: "nav.appUser", icon: "mdi-account" }
              }
            }
          ]
        },
        // profile
        {
          path: "profile",
          component: () => import("@/views/App/pages/admin"),
          redirect: "/profile/settings",
          children: [
            {
              path: "settings",
              name: "profile-settings",
              component: () => import("@/views/App/pages/profile/Setting"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "base.setting", href: "", disabled: true }
                ],
                pageTitle: { text: "base.setting", icon: "mdi-cog" }
              }
            }
          ]
        },
        // develop
        {
          path: "dev",
          component: () => import("@/views/App/pages/dev"),
          redirect: "/dev/permission",
          children: [
            {
              path: "permission",
              name: "dev-permission",
              component: () => import("@/views/App/pages/dev/Permission"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  {
                    text: "model.permission.permission",
                    href: "",
                    disabled: true
                  }
                ],
                pageTitle: {
                  text: "nav.permission.permission",
                  icon: "mdi-key-variant"
                }
              }
            },
            {
              path: "api-client",
              name: "dev-api-client",
              component: () => import("@/views/App/pages/dev/ApiClient"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  {
                    text: "model.api_client.api_client",
                    href: "",
                    disabled: true
                  }
                ],
                pageTitle: {
                  text: "nav.api_client.api_client",
                  icon: "mdi-api"
                }
              }
            },
            {
              path: "edr-std-mapper",
              name: "dev-edr-std-mapper",
              component: () => import("@/views/App/pages/dev/EdrStdMapper"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  {
                    text: "model.edr_std_mapper.edr_std_mapper",
                    href: "",
                    disabled: true
                  }
                ],
                pageTitle: {
                  text: "nav.edr_std_mapper.edr_std_mapper",
                  icon: "mdi-alphabetical"
                }
              }
            },
            {
              path: "colleage",
              name: "dev-edr-colleage",
              component: () => import("@/views/App/pages/dev/EdrColleage"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  {
                    text: "model.edr_colleage.edr_colleage",
                    href: "",
                    disabled: true
                  }
                ],
                pageTitle: {
                  text: "model.edr_colleage.edr_colleage",
                  icon: "mdi-flag"
                }
              }
            },
            {
              path: "deploy-edr",
              name: "dev-deploy-edr",
              component: () => import("@/views/App/pages/dev/DeployEdr"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "nav.deployEdrApp", href: "", disabled: true }
                ],
                pageTitle: { text: "nav.deployEdrApp", icon: "mdi-console" }
              }
            },
            {
              path: "edr-sql-log",
              name: "dev-edr-sql-log",
              component: () => import("@/views/App/pages/dev/EdrSqlLog"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  { text: "nav.sqlLogs", href: "", disabled: true }
                ],
                pageTitle: { text: "nav.sqlLogs", icon: "mdi-database-edit" }
              }
            },
            {
              path: "app-constant",
              name: "dev-app-constant",
              component: () => import("@/views/App/pages/dev/AppConstant"),
              meta: {
                breadcrumb: [
                  { text: "nav.dashboard", href: "/", disabled: false },
                  {
                    text: "model.app_constant.app_constant",
                    href: "",
                    disabled: true
                  }
                ],
                pageTitle: {
                  text: "model.app_constant.app_constant",
                  icon: "mdi-variable"
                }
              }
            }
          ]
        },
        // setting
        {
          name: "theme-settings",
          path: "settings/theme",
          component: () => import("@/views/App/settings/Theme"),
          meta: {
            breadcrumb: [
              { text: "nav.dashboard", href: "/", disabled: false },
              { text: "nav.themes", href: "", disabled: true }
            ],
            pageTitle: { text: "nav.themes", icon: "mdi-user" }
          }
        },
        {
          name: "restart-edr",
          path: "restart-edr",
          component: () => import("@/views/App/RestartEdr"),
          meta: {
            breadcrumb: [
              { text: "nav.dashboard", href: "/", disabled: false },
              { text: "respartEdr", href: "", disabled: true }
            ],
            pageTitle: { text: "respartEdr", icon: "mdi-reload" }
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/views/Layout/Full"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/auth/signin",
          name: "authen-signin",
          component: () => import("@/views/Auth/Signin.vue")
        },
        {
          path: "/error/404",
          name: "error-404",
          component: () => import("@/views/Exception/Error404.vue")
        },
        {
          path: "check-in/:collegeCode/checkin",
          name: "check-in-checkin",
          component: () => import("@/views/App/CollegeCheckin/Checkin")
        }
      ]
    },
    // =============================================================================
    // COVID TRACKER PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/views/Layout/CollegeCheckin"),
      children: [
        {
          path: "check-in/:collegeCode/",
          component: () => import("@/views/App/CollegeCheckin"),
          redirect: "/check-in/:collegeCode/dashboard",
          children: [
            {
              path: "dashboard",
              name: "check-in-dashboard",
              component: () => import("@/views/App/CollegeCheckin/Dashboard")
            },
            {
              path: "qr",
              name: "check-in-qr",
              component: () => import("@/views/App/CollegeCheckin/QrCode")
            }
          ]
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error/404"
    }
  ]
});