<template>
  <div>
    <v-overlay
      v-if="type==='overlay'"
      :value="value"
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-else
      key="vee-loader-dialog"
      v-model="value"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-center white--text">
          {{$t('base.pleaseWait')}}
          <v-progress-linear
            :indeterminate="dialogvalue===0 && indeterminate"
            :value="dialogvalue"
            :background-opacity="opacity"
            :height="height"
            color="white"
            class="my-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "WeeLoader",
  props: {
    value: Boolean,
    type: {
      type: String,
      default: "overlay" //overlay, dialog
    },
    absolute: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: String,
      default: "0.46"
    },
    zIndex: {
      type: Number,
      default: 10
    },
    //dialog props
    indeterminate: {
      type: Boolean,
      default: true
    },
    dialogvalue: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 4
    }
  }
});
</script>
